import React from "react"
const tweets = [
  {
    id: "1441239497346142221",
    authorName: "Rick Houlihan",
    authorNick: "houlihan_rick",
    authorImage:
      "/images/testimonials/rick-houlihan.png",
    text: () => (
      <p>This will be my default modeling tool from now on. Nice job Rafal.</p>
    ),
    likes: 55,
    date: "5:14 am · 24 Sep 2021",
  },
  {
    id: "1248602510467534848",
    authorName: "Daniel Vassallo",
    authorNick: "dvassallo",
    authorImage:
      "/images/testimonials/daniel-vassallo.jpg",
    text: () => <p>"Well done! I use Dynobase everyday. Happy customer! 💪</p>,
    likes: 2,
    date: "3:23 PM · Apr 10, 2020",
  },
  {
    id: "1263516547831812097",
    authorName: "Tomasz Lakomy",
    authorNick: "tlakomy",
    authorImage:
      "/images/testimonials/tomasz-lakomy.jpg",
    text: () => (
      <div>
        <p>
          As a frontend developer, I used to be straight up _scared_ of
          databases 😱
        </p>
        <p>
          Things changed when I started using AWS DynamoDB at work but as
          awesome as it is - the DynamoDB Web Console is not perfect{" "}
        </p>
        <p>
          <a href="#">@dynobase</a> makes DynamoDB *much* easier to work with 🥳
        </p>
      </div>
    ),
    likes: 28,
    date: "7:06 PM · May 21, 2020",
  },
  {
    id: "1336105720350081028",
    authorName: "pete_naylor@AWS",
    authorNick: "pete_aws",
    authorImage:
      "/images/testimonials/pete-naylor.jpg",
    text: () => (
      <div>
        <p>
          I am FINALLY setting time aside to check out <a href="#">@dynobase</a>
          !!
        </p>
        <p>
          Awesome stuff so far: the capacity consumption feedback, and the
          configurable maximums around paginated reads and page sizes.
        </p>
        <p>
          Strong work{" "}
          <a
            href="https://twitter.com/RafalWilinski"
            rel="noreferrer noopener"
            target="_blank"
          >
            @rafalwilinski
          </a>{" "}
          💪
        </p>
      </div>
    ),
    likes: 7,
    date: "1:30 AM · Dec 8, 2020",
  },
  {
    id: "1252088380118241286",
    authorName: "Erik Peterson",
    authorNick: "silvexis",
    authorImage:
      "/images/testimonials/erik-peterson.jpg",
    text: () => (
      <div>
        <p>Two things every DynamoDB fan should have.</p>
        <p>
          <a
            href="https://twitter.com/alexdebrie"
            rel="noreferrer noopener"
            target="_blank"
          >
            @alexdebrie
          </a>
          's{" "}
          <a
            href="https://DynamoDBBook.com"
            rel="noreferrer noopener"
            target="_blank"
          >
            http://DynamoDBBook.com
          </a>{" "}
          and{" "}
          <a
            href="https://twitter.com/RafalWilinski"
            rel="noreferrer noopener"
            target="_blank"
          >
            @rafalwilinski
          </a>{" "}
          <a href="#">@dynobase</a> client
        </p>
      </div>
    ),
    likes: 11,
    date: "6:15 AM · Apr 20, 2020",
  },
  {
    id: "1333467895955787778",
    authorName: "Kirk Kirkconnell",
    authorNick: "NoSQLKnowHow",
    authorImage:
      "/images/testimonials/kirk-kirkconnell.jpg",
    text: () => (
      <div>
        <p>
          If you are interested in a great #DynamoDB client, this one is
          fantastic.
        </p>
        <p>
          And no, I am not forsaking #NoSQL Workbench. I just really like the
          work that the Dynobase team does and they make a great product.
        </p>
      </div>
    ),
    likes: 13,
    date: "6:48 PM · Nov 30, 2020",
  },
  {
    id: "1217241737548173312",
    authorName: "Joe Emison",
    authorNick: "JoeEmison",
    authorImage:
      "/images/testimonials/joe-emison.jpg",
    text: () => (
      <p>
        As a public service announcement, if you use DynamoDB and you haven't
        tried Dynobase (<a href="#">https://dynobase.dev</a>)... you should.
      </p>
    ),
    likes: 15,
    date: "1:27 AM · Jan 15, 2020",
  },

  {
    id: "1282644816443179008",
    authorName: "Ant Stanley",
    authorNick: "IamStan",
    authorImage:
      "/images/testimonials/ant-stanley.jpg",
    text: () => (
      <p>
        I was given a license for <a href="#">@dynobase</a> a little while ago,
        and it's quickly crept into my dev workflow. So much easier to interact
        with DynamoDB, over the AWS console. I would say if you work with
        DynamoDB it's a must have tool.
      </p>
    ),
    likes: 14,
    date: "1:55 PM · Jul 13, 2020",
  },
]

export default tweets
