import React from "react"
import { Box } from "rebass"

const Tweet = ({ tweet }) => {
  return (
    <Box
      sx={{
        width: "280px",
        border: "1px solid #e1e8ed",
        borderRadius: "4px",
        padding: "14px 20px",
        textDecoration: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <a
          href={`https://twitter.com/${tweet.authorNick}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt={tweet.authorName}
            src={tweet.authorImage}
            loading="lazy"
            style={{
              marginBottom: 0,
              borderRadius: "50%",
              height: "48px",
              width: "48px",
            }}
          />
        </a>
        <a
          href={`https://twitter.com/${tweet.authorNick}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "10px",
            textDecoration: "none",
          }}
        >
          <span
            className="flex items-center leading-5"
            title="{author.name}"
            style={{
              fontWeight: 700,
              color: "black",
              fontSize: "14px",
            }}
          >
            {tweet.authorName}
          </span>
          <span
            className="text-gray-500"
            title={`@${tweet.authorNick}`}
            style={{
              color: "#aaa",
              fontSize: "14px",
              lineHeight: 1,
              marginBottom: "10px",
            }}
          >
            {" "}
            @{tweet.authorNick}{" "}
          </span>
        </a>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            fontSize: "16px",
          }}
        >
          {tweet.text()}
        </span>
        <a
          href={`https://twitter.com/${tweet.authorNick}/status/${tweet.id}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            marginTop: "-20px",
          }}
        >
          <span
            style={{
              color: "#aaa",
              fontSize: "14px",
            }}
          >
            {" "}
            {tweet.date}{" "}
          </span>
        </a>
      </div>
    </Box>
  )
}

export default Tweet
