import React from "react"
import Masonry from "react-masonry-css"
import tweets from "./Data"
import Tweet from "./Tweet"

export default () => (
  <>
    <h2 style={{ marginBottom: "50px" }}>Endorsed by AWS Community</h2>
    <Masonry
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
      breakpointCols={{
        default: 3,
        880: 2,
        560: 1,
      }}
    >
      {tweets.map(tweet => (
        <Tweet tweet={tweet} key={tweet.id} />
      ))}
    </Masonry>
  </>
)
